import React from "react"
import { grandsPrix } from "core/api/grandsPrix"
import { manufacturers } from "core/api/manufacturers"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useRaceChassis(gpId, raceId) {
  const [chassis, setChassis] = React.useState([])

  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["races-chassis", { gpId, raceId }], () =>
    getToken().then((token) => {
      const result = grandsPrix.getChassisByRaceById(gpId, raceId, token)
      result.then((data) => {
        setChassis(data.chassis)
      })
      return result
    })
  )

  const addChassis = async (constructorId) => {
    const token = await getToken()
    if (!chassis.find((c) => c.constructorManufacturerId === constructorId)) {
      manufacturers
        .getChassisByConstructorManufacturerId(constructorId, token)
        .then((data) => {
          setChassis((prev) => [...prev, ...data.chassis])
        })
    }
  }

  return {
    ...queryResponse,
    chassis,
    addChassis,
  }
}
