import {
  Button,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import LoadingProgress from "components/Shared/LoadingProgress"
import Navigation from "components/Navigation/Index"
import React from "react"
import SeasonDrivers from "components/SeasonDrivers"
import useSeason from "core/hooks/useSeason"

export default function SeasonEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const seasonId = params.seasonId

  const { season } = useSeason(seasonId)

  if (!season) {
    return <LoadingProgress></LoadingProgress>
  }

  return (
    <Container>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='h2'>Edit season</Typography>
        </Grid>
        <Grid item xs={12}>
          <Navigation navs={[{ link: `/seasons/`, title: "Seasons" }]} />
        </Grid>
      </Grid>

      <Grid
        item
        xs={4}
        display='flex'
        justifyContent='flex-end'
        alignItems='center'
        alignSelf='stretch'
      >
        <Button
          variant='contained'
          onClick={() => navigate(`/seasons/${seasonId}/drivers-edit`)}
        >
          Edit drivers
        </Button>
      </Grid>

      <FormControl fullWidth>
        <Grid
          container
          columnSpacing={2}
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='seasonId'
              label='Id'
              defaultValue={season.id}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='seasonYear'
              label='Year'
              defaultValue={season.year}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='seasonRound'
              label='Round'
              defaultValue={season.round}
              fullWidth
              disabled
            />
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='seasonStatus'
              label='Status'
              defaultValue={season.status}
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </FormControl>
      <SeasonDrivers seasonId={season.id} seasonDrivers={season.drivers} />
    </Container>
  )
}
