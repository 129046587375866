import { MenuItem } from "@mui/material"
import SelectGroup from "components/Shared/SelectGroup"

export default function SeasonDrivingLevel({ drivingLevel, setDrivingLevel }) {
  return (
    <SelectGroup
      id='driving-level'
      label={"Driving Level"}
      value={drivingLevel}
      onChange={setDrivingLevel}
    >
      <MenuItem value='Practice'>Practice</MenuItem>
      <MenuItem value='Qualifying'>Qualifying</MenuItem>
      <MenuItem value='GrandPrix'>GrandPrix</MenuItem>
    </SelectGroup>
  )
}
