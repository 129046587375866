import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const seasons = {
  getSeason: (seasonId, token) => {
    return apiService
      .getData(`v1/seasons/${seasonId}`, token)
      .then(responseToJson)
  },
  getSeasons: (token) => {
    return apiService.getData(`v1/seasons`, token).then(responseToJson)
  },
  getSeasonById: (seasonId, token) => {
    return apiService
      .getData(`v1/seasons/${seasonId}`, token)
      .then(responseToJson)
  },
  updateDriverStatus: (seasonId, driverId, body, token) => {
    return apiService.update(
      `v1/seasons/${seasonId}/drivers/${driverId}/status`,
      JSON.stringify(body),
      token
    )
  },
  getSeasonTeams: (seasonId, token) => {
    return apiService
      .getData(`v1/seasons/${seasonId}/teams`, token)
      .then(responseToJson)
  },
  addSeason: (season, token) => {
    return apiService
      .create(`v1/seasons`, JSON.stringify(season), token)
      .then(responseToJson)
  },
  updateSeasonDrivers: (seasonId, command, token) => {
    return apiService
      .patch(`v1/seasons/${seasonId}/drivers`, JSON.stringify(command), token)
      .then(responseToJson)
  },
}
