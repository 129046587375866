import { Button, Grid, MenuItem } from "@mui/material"

import React from "react"
import SeasonDriverStatus from "components/SeasonDriverStatus"
import SeasonDrivingLevel from "components/SeasonDrivingLevel"
import SelectGroup from "components/Shared/SelectGroup"

export default function SeasonAddDriver({
  index,
  seasonDriver,
  drivers,
  constructors,
  canBeDeleted,
  handleRemoveDriver,
  setDriverId,
  setDriverStatus,
  setDrivingLevel,
  setConstructorId,
}) {
  return (
    <React.Fragment>
      {canBeDeleted && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant={"outlined"}
            color={"error"}
            onClick={() => handleRemoveDriver(index)}
          >
            Remove driver
          </Button>
        </Grid>
      )}

      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <SelectGroup
            id={`drivers-id-${index}`}
            label='Drivers'
            value={seasonDriver.driverId}
            gridSize={4}
            onChange={(value) => setDriverId(value, index)}
          >
            {drivers.map((driver) => (
              <MenuItem key={driver.id} value={driver.id}>
                {`${driver.firstName} ${driver.lastName}`}
              </MenuItem>
            ))}
          </SelectGroup>
        </Grid>

        <Grid item xs={4}>
          <SelectGroup
            id={`constructors-id-${index}`}
            label='Constructors'
            value={seasonDriver.constructorId}
            gridSize={4}
            onChange={(value) => setConstructorId(value, index)}
          >
            {constructors.map((constructor) => (
              <MenuItem key={constructor.id} value={constructor.id}>
                {`${constructor.name}`}
              </MenuItem>
            ))}
          </SelectGroup>
        </Grid>

        <Grid item xs={2}>
          <SeasonDriverStatus
            driverStatus={seasonDriver.driverStatus}
            setDriverStatus={setDriverStatus}
          />
        </Grid>

        <Grid item xs={2}>
          <SeasonDrivingLevel
            drivingLevel={seasonDriver.drivingLevel}
            setDrivingLevel={setDrivingLevel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
