import { Grid, Typography } from "@mui/material"

import FormContainer from "components/Shared/FormContainer"
import InputGroup from "components/Shared/InputGroup"
import React from "react"
import { useNavigate } from "react-router-dom"
import useSeasons from "core/hooks/useSeasons"

export default function SeasonCreatePage() {
  const { addSeason } = useSeasons()

  const [year, setYear] = React.useState(Date.now().year)
  const [url, setUrl] = React.useState("")
  const [round, setRound] = React.useState(24)

  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await addSeason({
      year,
      url,
      round,
    })

    if (result != null) {
      navigate(`/seasons/${result.id}/edit`)
      return
    }
  }
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4' component='h1'>
          Create Season
        </Typography>
      </Grid>

      <FormContainer handleSubmit={handleSubmit}>
        <Grid item xs={12}>
          <InputGroup
            id={`year`}
            label='Year'
            autocomplete={false}
            value={year}
            gridSize={12}
            onChange={(value) => setYear(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <InputGroup
            id={`url`}
            label='Url'
            autocomplete={false}
            value={url}
            gridSize={12}
            onChange={(value) => setUrl(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <InputGroup
            id={`round`}
            label='Round'
            autocomplete={false}
            value={round}
            gridSize={12}
            onChange={(value) => setRound(value)}
          />
        </Grid>
      </FormContainer>
    </Grid>
  )
}
