import React from "react"
import { grandsPrix } from "core/api/grandsPrix"
import { manufacturers } from "core/api/manufacturers"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useRaceEngines(gpId, raceId) {
  const [engines, setEngines] = React.useState([])
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["races-engines", { gpId, raceId }], () =>
    getToken().then((token) => {
      const result = grandsPrix.getEnginesByRaceById(gpId, raceId, token)
      result.then((data) => {
        setEngines(data.engines)
      })

      return result
    })
  )

  const addEngines = async (engineManufacturerId) => {
    const token = await getToken()
    if (!engines.find((c) => c.engineManufacturerId === engineManufacturerId)) {
      manufacturers
        .getEnginesByEngineManufacturerId(engineManufacturerId, token)
        .then((data) => {
          setEngines((prev) => [...prev, ...data.engines])
        })
    }
  }

  return {
    ...queryResponse,
    engines,
    addEngines,
  }
}
