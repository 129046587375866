import * as React from "react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TableCell,
  TableRow,
} from "@mui/material"

import ActionTableCell from "components/Shared/ActionTableCell"
import SeasonDriverStatus from "components/SeasonDriverStatus"
import SeasonDrivingLevel from "components/SeasonDrivingLevel"
import useSeason from "core/hooks/useSeason"

export default function SeasonDriverConstructor({
  seasonId,
  driver,
  constructor,
}) {
  const [open, setOpen] = React.useState(false)
  const [driverStatus, setDriverStatus] = React.useState(
    constructor.driverStatus
  )
  const [drivingLevel, setDrivingLevel] = React.useState(
    constructor.drivingLevel
  )
  const { updateDriverStatus, refetch } = useSeason(seasonId)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveInformations = async () => {
    var body = {
      constructorId: constructor.id,
      drivingLevel: drivingLevel,
      driverStatus: driverStatus,
    }
    var response = await updateDriverStatus(driver.id, body)
    if (response.status === 200) {
      handleClose()
      refetch()
    } else {
      console.error(response)
    }
  }

  return (
    <TableRow
      key={constructor.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component='th' scope='row'>
        {constructor.name}
      </TableCell>
      <TableCell component='th' scope='row'>
        {constructor.driverStatus}
      </TableCell>
      <TableCell component='th' scope='row'>
        {constructor.drivingLevel}
      </TableCell>
      <TableCell component='th' scope='row'>
        <ActionTableCell
          actions={[
            {
              variant: "outlined",
              title: "Edit",
              onClick: () => handleClickOpen(),
            },
          ]}
        />
      </TableCell>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Update {driver.firstName + " " + driver.lastName} status
        </DialogTitle>
        <DialogContent>
          {constructor.name}
          <DialogContentText id='alert-dialog-description'>
            <FormControl fullWidth>
              <Grid
                container
                columnSpacing={2}
                paddingRight={6}
                paddingLeft={6}
                paddingBottom={2}
                paddingTop={2}
                rowSpacing={2}
              >
                <Grid
                  item
                  xs={24}
                  display='flex'
                  justifyContent='left'
                  alignItems='left'
                >
                  <SeasonDriverStatus
                    driverStatus={driverStatus}
                    setDriverStatus={setDriverStatus}
                  />
                </Grid>
                <Grid
                  item
                  xs={24}
                  display='flex'
                  justifyContent='left'
                  alignItems='left'
                >
                  <SeasonDrivingLevel
                    setDrivingLevel={setDrivingLevel}
                    drivingLevel={drivingLevel}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Discard</Button>
          <Button onClick={handleSaveInformations} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </TableRow>
  )
}
