import { Button, Grid } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import FormContainer from "components/Shared/FormContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import React from "react"
import SeasonAddDriver from "components/SeasonAddDriver"
import useAllConstructors from "core/hooks/useAllConstructors"
import useAllDrivers from "core/hooks/useAllDrivers"
import useSeason from "core/hooks/useSeason"

export default function SeasonEditDriversPage() {
  const navigate = useNavigate()
  const params = useParams()
  const seasonId = params.seasonId
  const { drivers, isLoading: isLoadingDrivers } = useAllDrivers()
  const { constructors, idLoading: isLoadingConstructors } =
    useAllConstructors()

  const [seasonDrivers, setSeasonDrivers] = React.useState([])
  const { updateSeasonDrivers } = useSeason(seasonId)

  React.useEffect(() => {
    if (constructors?.length > 0 && drivers?.length > 0) {
      setSeasonDrivers([
        {
          driverId: drivers[0].id,
          constructorId: constructors[0].id,
          driverStatus: "Main",
          drivingLevel: "GrandPrix",
        },
      ])
    }
  }, [drivers, constructors])

  const handleAddDriver = () => {
    setSeasonDrivers([
      ...seasonDrivers,
      {
        driverId: drivers[0].id,
        constructorId: constructors[0].id,
        driverStatus: "Main",
        drivingLevel: "GrandPrix",
      },
    ])
  }

  const handleRemoveDriver = (index) => {
    const newSeasonDrivers = seasonDrivers.filter((_, i) => i !== index)
    setSeasonDrivers(newSeasonDrivers)
  }

  const setSeasonDriverValue = (value, index, property) => {
    const newSeasonDriver = seasonDrivers.map((seasonDriver, i) => {
      if (i === index) {
        return {
          ...seasonDriver,
          [property]: value,
        }
      }

      return seasonDriver
    })

    setSeasonDrivers(newSeasonDriver)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await updateSeasonDrivers(seasonDrivers)
    navigate(`/seasons/${seasonId}/edit`)
  }

  if (isLoadingDrivers || isLoadingConstructors) {
    return <LoadingProgress />
  }

  return (
    <FormContainer title={"Edit season drivers"} handleSubmit={handleSubmit}>
      <Grid container paddingY={4}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={handleAddDriver}
          >
            Add driver
          </Button>
        </Grid>
      </Grid>

      {seasonDrivers?.map((seasonDriver, index) => (
        <SeasonAddDriver
          key={index}
          index={index}
          canBeDeleted={seasonDrivers.length > 1}
          seasonDriver={seasonDriver}
          drivers={drivers}
          handleRemoveDriver={handleRemoveDriver}
          setDriverId={(value, index) => {
            setSeasonDriverValue(value, index, "driverId")
          }}
          constructors={constructors}
          setConstructorId={(value, index) => {
            setSeasonDriverValue(value, index, "constructorId")
          }}
          setDriverStatus={(value, index) => {
            setSeasonDriverValue(value, index, "driverStatus")
          }}
          setDrivingLevel={(value, index) => {
            setSeasonDriverValue(value, index, "drivingLevel")
          }}
        />
      ))}
    </FormContainer>
  )
}
