import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const drivers = {
  getDrivers: (page, isCurrentSeason, token) => {
    return apiService
      .getData(
        `v1/drivers?isCurrentSeason=${isCurrentSeason}&pageNumber=${page}&pageSize=50&sortBy=asc(Name)`,
        token
      )
      .then(responseToJson)
  },
  getDriverById: (driverId, token) => {
    return apiService
      .getData(`v1/drivers/${driverId}`, token)
      .then(responseToJson)
  },
  getDriverHistoryById: (driverId, historyId, token) => {
    return apiService
      .getData(`v1/drivers/${driverId}/histories/${historyId}`, token)
      .then(responseToJson)
  },
  getDriversHistoriesByDriverId: (driverId, token) => {
    return apiService
      .getData(`v1/drivers/${driverId}/histories`, token)
      .then(responseToJson)
  },
  updateDriver: (driverId, body, token) => {
    return apiService.patch(
      `v1/drivers/${driverId}`,
      JSON.stringify(body),
      token
    )
  },
  updateDriverHistory: (driverId, historyId, body, token) => {
    return apiService.patch(
      `v1/drivers/${driverId}/histories/${historyId}`,
      JSON.stringify(body),
      token
    )
  },
  getAllDrivers: (token) => {
    return apiService
      .getData(`v1/drivers?sortBy=asc(Name)&all=true`, token)
      .then(responseToJson)
  },
}
