import { MenuItem, Select, TableCell, TableRow, TextField } from "@mui/material"

import DisplayGroup from "components/Shared/DisplayGroup"
import GroupContainer from "components/Shared/GroupContainer"

export default function RaceParticipationEdit({
  participation,
  updateParticipation,
  updateTeam,
  updateTeamConstructor,
  updateTeamTyreManufacturer,
  setSuccessDisplay,
  setErrorDisplay,
  teams,
  tyreManufacturers,
  chassis,
  engines,
  updateTeamChassis,
  updateTeamEngine,
}) {
  const displayResultTime = () => {
    if (participation.position === 1) {
      return participation.result.time
    }

    if (
      participation.result.time == null &&
      participation.result.lapsBehindLeader == null
    ) {
      return
    }

    if (participation.result.time == null) {
      return `+${participation.result.lapsBehindLeader} laps`
    }

    return `+${participation.result.time}s`
  }

  const currentTeam = () => {
    return teams.find((team) => {
      return team.teamId === participation.team.id
    })
  }

  return (
    <TableRow
      key={participation.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component='td' scope='row' size='small'>
        <GroupContainer>
          <TextField
            id={`position-${participation.id}`}
            label='Pos'
            defaultValue={participation.result.position}
            size='small'
            onChange={(event) => {
              setSuccessDisplay(false)
              setErrorDisplay(false)
              updateParticipation(
                participation.id,
                "result.position",
                event.target.value
              )
            }}
          />

          <TextField
            id={`finishedPosition-${participation.id}`}
            size='small'
            label='FS Pos'
            defaultValue={participation.result.finishedPosition}
            onChange={(event) => {
              setSuccessDisplay(false)
              setErrorDisplay(false)
              updateParticipation(
                participation.id,
                "result.finishedPosition",
                event.target.value
              )
            }}
          />
        </GroupContainer>
      </TableCell>
      <TableCell component='td' scope='row'>
        <DisplayGroup
          groups={[
            { title: "grid", value: participation.result.grid },
            {
              title: "laps",
              value: participation.result.laps,
            },
            {
              title: "points",
              value: participation.result.points,
            },
            {
              title: "time",
              value: displayResultTime(),
            },
          ]}
        />
      </TableCell>
      <TableCell component='td' scope='row'>
        <Select
          id={`result-status-${participation.id}`}
          value={participation.result.resultStatus.status}
          onChange={(event) => {
            setSuccessDisplay(false)
            setErrorDisplay(false)
            updateParticipation(
              participation.id,
              "result.resultStatus.status",
              event.target.value
            )
          }}
        >
          <MenuItem value={"Ok"}>Ok</MenuItem>
          <MenuItem value={"Dnf"}>Dnf</MenuItem>
          <MenuItem value={"Dns"}>Dns</MenuItem>
          <MenuItem value={"Dsq"}>Dsq</MenuItem>
        </Select>
      </TableCell>
      <TableCell component='td' scope='row'>
        <DisplayGroup
          groups={[
            {
              title: "driver",
              value: `${participation.driver.firstName} ${participation.driver.lastName}`,
            },
            {
              title: "constructor leg.",
              value: participation.constructor.name,
            },
          ]}
        />
      </TableCell>
      <TableCell component='td' scope='row'>
        {participation.team && (
          <GroupContainer>
            <Select
              label='Age'
              id={`result-team-${participation.team.id}`}
              value={participation.team.id}
              fullWidth={true}
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                updateTeam(participation.id, event.target.value)
              }}
            >
              {teams.map((team) => (
                <MenuItem value={team.teamId}>{team.shortName}</MenuItem>
              ))}
            </Select>

            <Select
              label='Contructor/Engine'
              id={`result-team-cm-${participation.team.constructorManufacturer.id}`}
              value={`${participation.team.constructorManufacturer.id}+${participation.team.engineManufacturer.id}`}
              fullWidth={true}
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                updateTeamConstructor(
                  participation.id,
                  participation.team.id,
                  event.target.value
                )
              }}
            >
              {currentTeam()?.constructors.map((constructor) => (
                <MenuItem
                  key={`${constructor.constructor.id}+${constructor.engineManufacturer.id}`}
                  value={`${constructor.constructor.id}+${constructor.engineManufacturer.id}`}
                >
                  {constructor.constructor.name} -{" "}
                  {constructor.engineManufacturer.name}
                </MenuItem>
              ))}
            </Select>

            <Select
              label='TyreManufacturer'
              id={`result-team-tyre-manufacturer-${participation.team.tyreManufacturer?.id}`}
              value={participation.team.tyreManufacturer?.id}
              fullWidth={true}
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                updateTeamTyreManufacturer(participation.id, event.target.value)
              }}
            >
              {tyreManufacturers.map((tyreManufacturer) => (
                <MenuItem key={tyreManufacturer.id} value={tyreManufacturer.id}>
                  {tyreManufacturer.name}
                </MenuItem>
              ))}
            </Select>
          </GroupContainer>
        )}
      </TableCell>
      <TableCell component='td' scope='row'>
        {participation.team && (
          <GroupContainer>
            <Select
              label='Chassis'
              id={`result-team-tyre-manufacturer-${participation.team.constructorManufacturer?.chassisId}`}
              value={participation.team.constructorManufacturer?.chassisId}
              fullWidth={true}
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                updateTeamChassis(participation.id, event.target.value)
              }}
            >
              {chassis.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              label='Engines'
              id={`result-team-tyre-manufacturer-${participation.team.engineManufacturer?.engineId}`}
              value={participation.team.engineManufacturer?.engineId}
              fullWidth={true}
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                updateTeamEngine(participation.id, event.target.value)
              }}
            >
              {engines.map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </GroupContainer>
        )}
      </TableCell>
    </TableRow>
  )
}
