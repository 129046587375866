import { useQuery, useQueryClient } from "react-query"

import { seasons } from "core/api/seasons"
import { useAuth0 } from "@auth0/auth0-react"

export default function useSeason(seasonId) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["season", seasonId], () =>
    getToken().then((token) => {
      return seasons.getSeason(seasonId, token)
    })
  )

  const updateSeasonDrivers = async (seasonDrivers) => {
    const token = await getToken()
    queryClient.removeQueries("season")
    return seasons.updateSeasonDrivers(
      seasonId,
      { drivers: seasonDrivers },
      token
    )
  }

  const updateDriverStatus = async (driverId, body) => {
    const token = await getToken()
    queryClient.removeQueries("season")
    return seasons.updateDriverStatus(seasonId, driverId, body, token)
  }

  return {
    ...queryResponse,
    season: queryResponse?.data,
    updateSeasonDrivers,
    updateDriverStatus,
  }
}
