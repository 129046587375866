import { MenuItem } from "@mui/material"
import SelectGroup from "components/Shared/SelectGroup"

export default function SeasonDriverStatus({ driverStatus, setDriverStatus }) {
  return (
    <SelectGroup
      id='driver-status'
      label={"Driver Status"}
      value={driverStatus}
      onChange={setDriverStatus}
    >
      <MenuItem value='Main'>Main</MenuItem>
      <MenuItem value='Substitute'>Substitute</MenuItem>
      <MenuItem value='Reservist'>Reservist</MenuItem>
      <MenuItem value='TestPilot'>TestPilot</MenuItem>
    </SelectGroup>
  )
}
